import { useEagerConnect, useInactiveListener } from '@/web3/js/hooks';


function MetamaskProvider({ children }) {

  const triedEager = useEagerConnect()

  // handle logic to connect in reaction to certain events on the injected ethereum provider, if it exists
  useInactiveListener(!triedEager)

  if (triedEager) {
    return children
  }
  return <>Loading</>
}

export default MetamaskProvider
