import Box from '@/components/Box';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import './RoadMap.css';



export default function RoadMap() {
    return (
        <Box alignItems="center" justifyContent="center" style={{background: "linear-gradient(#039BE5, #03a8f8)" }}>
            <VerticalTimeline>
                <VerticalTimelineElement
                className="vertical-timeline-element--work"
                date="August 2021"
                iconStyle={{ background: '#03E298', color: '#fff' }}
                >
                <h3 className="vertical-timeline-element-title">Founded</h3>
                <h4 className="vertical-timeline-element-subtitle">Virtual, HQ</h4>
                <p>
                    Team founded the project and began working on the artwork.
                </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                className="vertical-timeline-element--work"
                date="September 11, 2021 - 7pm UTC"
                iconStyle={{ background: '#03E298', color: '#fff' }}
                >
                <h3 className="vertical-timeline-element-title">Minting Launch</h3>
                <h4 className="vertical-timeline-element-subtitle">Etherbulls.org</h4>
                <p>
                    The artwork is finalized. A hand-crafted collection of 6666 Ether bulls & bears are amassed in the minting contract. The token launches to the public - minting occurs on the homepage of our website etherbulls.org!
                </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                className="vertical-timeline-element--work"
                date="October 2021"
                iconStyle={{ background: '#03E298', color: '#fff' }}
                >
                <h3 className="vertical-timeline-element-title">Feature Release</h3>
                <h4 className="vertical-timeline-element-subtitle">On Etherbulls.org</h4>
                <p>
                    A surprise Ether Bull vs. Bear platform feature will be released! (pssst - who will win?)
                </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                className="vertical-timeline-element--work"
                date="November 2021"
                iconStyle={{ background: '#03E298', color: '#fff' }}
                >
                <h3 className="vertical-timeline-element-title">Mystery Announcement</h3>
                <h4 className="vertical-timeline-element-subtitle">Community Driven</h4>
                <p>
                    ?
                </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                iconStyle={{ background: '#03E298', color: '#fff' }}
                />
            </VerticalTimeline>
        </Box>
    );
}
