import {ethers} from 'ethers';

import CryptoBullsAbi from '@/web3/contracts/abi/EtherBulls.json';

export const makeContract = (provider) => {
    if (!!!process.env.REACT_APP_CONTRACT_ADDRESS || !!!CryptoBullsAbi || !!!CryptoBullsAbi.abi || !!!provider) {
        return undefined
    }

    return new ethers.Contract(
            process.env.REACT_APP_CONTRACT_ADDRESS,
            JSON.stringify(CryptoBullsAbi.abi),
            provider,
        );
    }