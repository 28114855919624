import {
  HashRouter as Router,
  // BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';

import { Web3ReactProvider } from '@web3-react/core';

import NavBar from '@/components/NavBar';
import Footer from '@/components/Footer';
import HomeScene from '@/components/HomeScene';
import MyBullScene from '@/components/MyBullScene';
import OddsDisclosure from '@/components/OddsDisclosure';
import SaleTermsScene from '@/components/SaleTermsScene';
import MetamaskProvider from '@/components/MetamaskProvider';
import MetaVerse from '@/components/MetaVerse';

import { Web3Provider } from '@ethersproject/providers'

import './App.css';


function getLibrary(provider) {
  const library = new Web3Provider(provider)
  library.pollingInterval = 10000
  return library
}


function App() {
  return (
      <Web3ReactProvider getLibrary={getLibrary}>
        <MetamaskProvider>
          <Router>
            <Switch>
              {/* <Route path="/metadata/tokens/:tokenId/metadata.json" component={MetaData} /> */}
              <Route path="/">
                <div className="App">
                  <div className="content">
                          <NavBar />
                          <Switch>
                              <Route path="/metaverse" exact>
                                  <MetaVerse />
                              </Route>
                              <Route path="/chances" exact>
                                  <OddsDisclosure />
                              </Route>
                              <Route path="/my-nfts" exact>
                                  <MyBullScene />
                              </Route>
                              <Route path="/toc" exact>
                                  <SaleTermsScene />
                              </Route>
                              <Route path="/" exact>
                                  <HomeScene />
                              </Route>
                          </Switch>
                  </div>
                  <Footer />
                </div>
              </Route>
            </Switch>
          </Router>
        </MetamaskProvider>
      </Web3ReactProvider>
  );
}

// function App() {
//   return (
//       <Web3ReactProvider getLibrary={getLibrary}>
//         <MetamaskProvider>
//           <Router>
//             <div className="App">
//               <div className="content">
//                   <Router>
//                       <NavBar />
//                       <Switch>
//                           <Route path="/chances" exact>
//                               <OddsDisclosure />
//                           </Route>
//                           <Route path="/my-nfts" exact>
//                               <MyBullScene />
//                           </Route>
//                           <Route path="/toc" exact>
//                               <SaleTermsScene />
//                           </Route>
//                           <Route path="/" exact>
//                               <HomeScene />
//                           </Route>
//                       </Switch>
//                   </Router>
//               </div>
//               <Footer />
//             </div>
//           </Router>
//         </MetamaskProvider>
//       </Web3ReactProvider>
//   );
// }

export default App;
