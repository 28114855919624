import * as React from 'react';

import Box from '@/components/Box';

import Blockies from 'react-blockies';

import { useWeb3 } from '@/web3/js/web3utils';
import { ethers } from "ethers";

import { useWeb3React, UnsupportedChainIdError } from '@web3-react/core';
import {
    NoEthereumProviderError,
    UserRejectedRequestError as UserRejectedRequestErrorInjected
} from '@web3-react/injected-connector';
import { injected } from '@/web3/js/connectors';


import {useMediaQuery} from 'react-responsive';

import {Link} from 'react-router-dom';

import Icon from '@/assets/logos/etherbulls.png';
import DiscordLogoWhite from '@/assets/DiscordLogoWhite.png';
import TwitterLogoWhite from '@/assets/TwitterLogoWhite.svg';

import './NavBar.css';


function getErrorMessage(error) {
    if (error instanceof NoEthereumProviderError) {
      return 'No Ethereum browser extension detected, install MetaMask on desktop or visit from a dApp browser on mobile.'
    } else if (error instanceof UnsupportedChainIdError) {
      return "You're connected to an unsupported network."
    } else if (
      error instanceof UserRejectedRequestErrorInjected
    ) {
      return 'Please authorize this website to access your Ethereum account.'
    } else {
      console.error(error)
      return 'An unknown error occurred. Check the console for more details.'
    }
  }


export default function NavBar() {
    const [isAvailable, setAvailable] = React.useState('');
    const [provider, setProvider] = React.useState('');


    const { connector, library, chainId, account, activate, deactivate, active, error } = useWeb3React();
    const loading=false;
    const connect = () => activate(injected).catch(()=>{});
    const switchChain = () => connector?.changeChainId ? connector?.changeChainId(process.env.REACT_APP_CHAIN_ID) : "";

    React.useEffect(() => {
        // const injected = new InjectedConnector({ supportedChainIds: [1, 3, 4, 5, 42] });
        // setAvailable(await injected.isAuthorized());
        // console.log(await injected.isAuthorized())

        async function setprov() {
            if (connector) {
                setProvider(new ethers.providers.Web3Provider(await connector.getProvider()));
            }
            else {
                setProvider("");
            }
            console.log("ping", connector, provider)
            // setAvailable(!!connector);
            setAvailable(true);
        }

        setprov();
    }, []);

    // const {loading, isAvailable, provider, account, chainId, connect, switchChain} = useWeb3();

    const centerNav = useMediaQuery({
        query: '(max-width: 1000px)',
    });

    const superSmall = useMediaQuery({
        query: '(max-width: 440px)',
    });

    const renderLoading = () => null;
    const renderWalletInfo = () => {
        return (
            <Box className="cr-navbar-wallet-info" direction="row" alignItems="center">
                <Box className="cr-navbar-wallet-info-avatar">
                    <Blockies seed={account} size={4} scale={4} />
                </Box>
                <span className="numeric" style={{fontSize: 14}}>{account.substr(0, 6)}…{account.substr(account.length - 4, 4)}</span>
            </Box>
        );
    };

    const renderMetamaskNotInstalled = () => {
        return (
            <Box className="cr-navbar-wallet-info" direction="row" alignItems="center">
                {/* <div style={{backgroundColor: 'red', width: 4, height: 4, borderRadius: 999, marginRight: 8}} /> */}
                <span style={{color: 'red', fontSize: 14}}><a href="https://metamask.io/" target="_blank" style={{all: "unset", color: "red", cursor: "pointer"}}>Metamask not installed</a></span>
            </Box>
        );
    };

    const renderOtherError = () => {
        return (
            <Box className="cr-navbar-wallet-info" direction="row" alignItems="center">
                {/* <div style={{backgroundColor: 'red', width: 4, height: 4, borderRadius: 999, marginRight: 8}} /> */}
                <span style={{color: 'red', fontSize: 14}}>{getErrorMessage(error)}</span>
            </Box>
        );
    };

    const renderWrongChain = () => {
        return (
            <Box className="cr-navbar-wallet-info" direction="row" alignItems="center">
                <span style={{color: 'red', fontSize: 14}}>! Wrong chain</span>
            </Box>
        );
    };

    const wrongChain = chainId !== null && chainId !== undefined && +chainId !== +process.env.REACT_APP_CHAIN_ID;

    React.useEffect(() => {
        if (wrongChain) {
            switchChain && switchChain();
        }
    }, [wrongChain]);

    return (
        <Box className="cr-navbar" direction={centerNav ? 'column' : 'row'} alignItems="center" justifyContent={centerNav ? 'center' : 'flex-start'}>
            <Box direction="row">
                <a href="/">
                    <Box direction="row" alignItems="center">
                        <img src={Icon} className="cr-navbar-logo-icon" />
                        <span className="cr-navbar-logo">EtherBulls</span>
                    </Box>
                </a>
            </Box>
            <Box direction="row" style={centerNav ? {marginTop: 12, alignItems: 'center'} : {flex: 1, alignItems: 'center'}}>
                <Link to="/my-nfts">My Bulls and Bears</Link>
                <Link to="/metaverse">Metaverse</Link>

                {!centerNav && (
                    <>
                        <div className="spacer" />
                        {!loading && !isAvailable && renderMetamaskNotInstalled()}
                        {!!error && renderOtherError()}
                        {!loading && isAvailable && wrongChain && renderWrongChain()}
                        {!!!error && !loading && isAvailable && !wrongChain && (account ? renderWalletInfo() : <button className="cr-navbar-connect" onClick={() => connect()}>Connect Wallet</button>)}
                    </>
                )}
            </Box>
            {/* { superSmall && (
                <Box direction="row" style={{marginTop: 8}}>
                    <a href="https://discord.gg/NMDgZsxpdM" target="_blank" style={{display: 'flex', justifyContent: 'center', marginTop: 4, marginLeft: 0}}><img src={DiscordLogoWhite} style={{width: 16, height: 18}} /></a>
                    <a href="https://twitter.com/etherbulls" target="_blank" style={{display: 'flex', justifyContent: 'center', marginTop: 2}}><img src={TwitterLogoWhite} style={{width: 16, height: 18}} /></a>
                </Box>
            )} */}
            { centerNav && (
                <Box style={{marginTop: 8}}>
                    <div className="spacer" />
                    {!loading && !isAvailable && renderMetamaskNotInstalled()}
                    {!!error && renderOtherError()}
                    {!loading && isAvailable && wrongChain && renderWrongChain()}
                    {!!!error && !loading && isAvailable && !wrongChain && (account ? renderWalletInfo() : <button className="cr-navbar-connect" onClick={() => connect()}>Connect Wallet</button>)}
                </Box>
            )}
        </Box>
    );
}