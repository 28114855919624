import Iframe from 'react-iframe';
import Box from '@/components/Box';
import {useMediaQuery} from 'react-responsive';


export default function MetaVerse() {

    const isMobile = useMediaQuery({
        query: '(max-width: 1000px)',
    });


    return (
        <Box className="" alignItems="center" justifyContent="center">
            {
                isMobile ?
                <span>Mobile Metaverse not supported. Please use desktop.</span> :
                <Iframe url="https://oncyber.io/etherbulls"
                    width="70%"
                    height="600px"
                    id="myId"
                    className="myClassname"
                    display="initial"
                    position="relative"
                    allow="xr-spatial-tracking"/>
            }
        </Box>
        );
}