import Box from '@/components/Box';

export default function SaleTermsScene() {
    return (
        <Box style={{paddingLeft: 64, paddingRight: 64, paddingTop: 16, paddingBottom: 16, textAlign: "left"}}>
            <span style={{fontSize: 32, marginBottom: 16, fontWeight: 'bold'}}>Terms</span>
            <span>
            EtherBulls is a collection of digital artworks (NFTs) running on the Ethereum network. This website is only an interface allowing participants to purchase and view their EtherBulls digital collectibles (each referred to as an "Ether Bull" or "Ether Bear"). Users are entirely responsible for the safety and management of their own private Ethereum wallets and validating all transactions and contracts generated by this website before approval. Furthermore, as the EtherBulls smart contract runs on the Ethereum network, there is no ability to undo, reverse, or restore any transactions.

            This website and its connected services are provided “as is” and “as available” without warranty of any kind. By using this website you are accepting sole responsibility for any and all transactions involving EtherBulls digital collectibles.
            </span>

            <span style={{fontSize: 24, marginTop: 20, marginBottom: 8}}>OWNERSHIP</span>
            <span>
                i. You Own the NFT. Each Ether Bull or Bear is an NFT on the Ethereum blockchain. When you purchase an NFT from EtherBulls, you own the underlying Ether Bull or Bear, the Art, in its entirety. Ownership of the NFT is mediated entirely by the Smart Contract and the Ethereum Network: at no point may we seize, freeze, or otherwise modify the ownership of any Ether Bull or Bear.<br />
                <br />
                ii. Personal Use. Subject to your continued compliance with these Terms, the EtherBulls developers grant you a worldwide, royalty-free license to use, copy, and display the purchased Art, along with any extensions that you choose to create or use, solely for the following purposes: (i) for your own personal, non-commercial use; or (ii) as part of a third party website or application that permits the inclusion, involvement, or participation of your Ether Bull or Bear, provided that the website/application cryptographically verifies each Ether Bull's owner’s rights to display the Art for their Ether Bull or Bear to ensure that only the actual owner can display the Art, and provided that the Art is no longer visible once the owner of the Ether Bull or Bear leaves the website/application.<br />
                <br />
                iii. Commercial Use. Subject to your continued compliance with these Terms, the EtherBulls developers grant you an unlimited, worldwide license to use, copy, and display the purchased Art for the purpose of creating derivative works based upon the Art (“Commercial Use”). Examples of such Commercial Use would e.g. be the use of the Art to produce and sell merchandise products (T-Shirts etc.) displaying copies of the Art. For the sake of clarity, nothing in this Section will be deemed to restrict you from (i) owning or operating a third party website or application that permits the inclusion, involvement, or participation of EtherBulls generally, provided that the third party website or application cryptographically verifies each Ether Bull or Bear owner’s rights to display the Art for their Ether Bull or Bear to ensure that only the actual owner can display the Art, and provided that the Art is no longer visible once the owner of the Purchased Ether Bull or Bear leaves the website/application; or (ii) earning revenue from any of the foregoing.
            </span>

            <span style={{fontSize: 24, marginTop: 20, marginBottom: 8}}>ELIGIBILITY</span>
            <span>
            By agreeing to these Terms, you represent and warrant that:
            <br />
            (i) You are at least 18 years of age;
            <br />
            (ii) You have the full right, power, and authority to agree to these Terms;
            <br />
            (iii) You are not subject to any financial sanctions, embargoes or other restrictive measures imposed by the United Nations, European Union, any EU country, UK Treasury or US Office of Foreign Assets Control (OFAC), or any governmental authority in any jurisdiction in which EtherBulls is accessible;
            <br />
            (iv) You are not a citizen or resident of any of the following countries:
            <br />
            a. Belarus
            <br />
            b. Burma (Myanmar)
            <br />
            c. Central African Republic
            <br />
            d. Cote d'Ivoire (Ivory Coast)
            <br />
            e. Cuba
            <br />
            f. Democratic Republic of the Congo
            <br />
            g. Iran
            <br />
            h. Iraq
            <br />
            i. Lebanon
            <br />
            j. Liberia (former Liberian Regime of Charles Taylor)
            <br />
            k. Libya
            <br />
            l. North Korea
            <br />
            m. Somalia
            <br />
            n. Sudan
            <br />
            o. Syria
            <br />
            p. Zimbabwe
            <br />
            (v) You are not impersonating any other person;
            <br />
            (vi) You will not purchase EtherBulls if any Applicable Laws in your country prohibit you from doing so in accordance with these Terms;
            <br />
            (vii) You are compliant with all Applicable Laws to which you are subject;
            </span>

            <span style={{fontSize: 24, marginTop: 20, marginBottom: 8}}>RISKS</span>
            <span>

            You understand and agree that your access and use of EtherBulls is subject to certain risks including without limitation:
            <br />
            (i) Price and liquidity of blockchain assets, including the NFTs, are extremely volatile and may be subject to fluctuations;
            <br />
            (ii) Fluctuations in the price of other digital assets could materially and adversely affect the NFTs;
            <br />
            (iii) Legislative and regulatory changes or actions may adversely affect the use, transfer, and value of the NFTs;
            <br />
            (iv) NFTs are not legal tender and are not back by any government;
            <br />
            (v) Transactions involving NFTs may be irreversible, and losses due to fraudulent or accidental transactions may not be recoverable;
            <br />
            (vi) The value of NFTs may be derived from the continued willingness of market participants to exchange fiat currency or digital assets for NFTs, and therefore the value of NFTs is subject to the potential for permanent or total loss of value should the market for NFTs disappear;
            <br />
            (vii) NFTs are subject to the risk of fraud, counterfeiting, cyber attacks and other technological difficulties which may prevent access to or use of your NFTs; and
            <br />
            (viii) Withdrawal of an NFT to a decentralised digital asset wallet is at your own risk, and the transfer for any NFT to an incorrect digital asset wallet address will result in the irreversible loss of such NFT.
            <br />
            You understand and agree that you are solely responsible for determining the nature, potential value, suitability and appropriateness of these risks for yourself. You understand and agree that you access and use the NFT Platform at your own risk. You understand and agree that EtherBulls will not be responsible for any communication failures, disruptions, errors, or distortions you may experience when using the NFTs or the NFT Platform.
            <br />
            </span>

            <span style={{fontSize: 24, marginTop: 20, marginBottom: 8}}>SEVERABILITY</span>
            <span>
            If any provision of these Terms shall be found by any court or administrative body of competent jurisdiction to be invalid or unenforceable, the invalidity or unenforceability of such provision shall not affect the other provisions of these Terms and all provisions not affected by such invalidity or unenforceability shall remain in full force and effect. Such provision will be changed and interpreted to accomplish the objectives of the provision to the greatest extent possible under any Applicable Laws.
            </span>

        </Box>
    );
}