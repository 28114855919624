import Box from '@/components/Box';

export default function OddsDisclosure() {
    return (
        <Box style={{paddingLeft: 64, paddingRight: 64, paddingTop: 16, paddingBottom: 16}}>
            <span style={{fontSize: 32, marginBottom: 16, fontWeight: 'bold'}}>Chances Disclosure</span>
            <span>Combinations:</span>
            <ul>
                <li></li>
            </ul>
        </Box>
    );
}