import Box from '@/components/Box';
import {Link} from 'react-router-dom';

import './Footer.css';

export default function Footer() {
    return (
        <Box className="cr-footer" alignItems="center" justifyContent="center">
            <span style={{fontWeight: 'bold'}}>&copy; 2021 Etherbulls. All rights reserved.</span>
            <span style={{whiteSpace: 'nowrap'}}><Link target="_blank" style={{color: 'inherit', textDecoration: 'underline'}} to="/toc"> Terms </Link> - <Link target="_blank" style={{color: 'inherit', textDecoration: 'underline'}} to="/chances"> Chances Disclosure</Link></span>
            <span></span>
        </Box>
    );
}