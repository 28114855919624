import * as React from 'react';

import {Link} from 'react-router-dom';

// import { useWeb3 } from 'react-web3-provider';
import { useWeb3 } from '@/web3/js/web3utils';
import { useWeb3React } from '@web3-react/core';
import { injected } from '@/web3/js/connectors';
import { ethers } from "ethers";



import Box from '@/components/Box';
import {makeContract} from '@/web3/contracts/EtherBulls';
import {load} from 'dotenv';



export default function MyBullScene() {
    const [rugs, setRugs] = React.useState([]);
    const [rugMetadata, setRugMetadata] = React.useState({});
    const [loadingRugs, setLoadingRugs] = React.useState(false);
    const [isAvailable, setAvailable] = React.useState('');
    const [provider, setProvider] = React.useState('');



    const { connector, library, chainId, account, activate, deactivate, active, error } = useWeb3React();
    const loading=false;
    const connect = () => activate(injected).catch(()=>{});

    React.useEffect(() => {
        // const injected = new InjectedConnector({ supportedChainIds: [1, 3, 4, 5, 42] });
        // setAvailable(await injected.isAuthorized());
        // console.log(await injected.isAuthorized())

        // async function setprov() {
        //     setProvider(new ethers.providers.Web3Provider(await connector.getProvider()));
        // }

        // if (connector) {
        //     setprov()
        // }
        // else {
        //     setProvider("");
        // }
        // setAvailable(!!provider);

        async function setprov() {
            if (connector) {
                setProvider(new ethers.providers.Web3Provider(await connector.getProvider()));
            }
            else {
                setProvider("");
            }
            console.log("ping", connector, provider)
            // setAvailable(!!connector);
            setAvailable(true);

        }

        setprov();
    }, []);


    // const {loading, isAvailable, provider, account, connect} = useWeb3();

    React.useEffect(() => {
        if (!provider || !account) {
            setRugs([]);
            return;
        }
        async function loadRugs() {
            setLoadingRugs(true);
            const cryptoRugs = makeContract(provider)?.connect(provider.getSigner());
            const numTokens = await cryptoRugs?.balanceOf(account);

            let tokenPromises = [];
            for (let i = 0; i < numTokens; i++) {
                tokenPromises.push(cryptoRugs.tokenOfOwnerByIndex(account, i));
            }
            const tokens = await Promise.all(tokenPromises);
            const metadataFetchPromises = tokens.map(async (token) => {
                const respJson = await fetch(`https://etherbulls.org/static/nfts/${token.toString()}/metadata.json`);
                const resp = await respJson.json();
                return [token.toString(), resp];
            });
            let metadataIdx = {};
            try {
                const tokensMetadata = await Promise.all(metadataFetchPromises);
                for (const [tokenId, tokenMetadata] of tokensMetadata) {
                    metadataIdx[tokenId] = tokenMetadata;
                }
            } catch { }

            setRugs(tokens.map((tokenId) => tokenId.toString()));
            setRugMetadata(metadataIdx);
            setLoadingRugs(false);
        }
        loadRugs();
    }, [provider, account]);

    return (
        <Box alignItems="center" style={{marginTop: 16, marginBottom: 16}}>
            <span style={{fontWeight: 'bold', fontSize: 32, marginBottom: 16}}>Your Bulls and Bears</span>
            {loadingRugs && <span style={{fontWeight: 'bold'}}>Loading...</span>}
            {!loadingRugs && rugs.length === 0 && <span>You have no bulls or bears. <Link to="/" style={{color: '#03E298', fontWeight: 'bold', textDecoration: 'none'}}>Try minting!</Link></span>}
            <Box direction="row" style={{flexWrap: 'wrap'}} justifyContent="center">
                {rugs.map((tokenId) => {
                    const metadata = rugMetadata[tokenId];

                    return (
                        <Box alignItems="center" style={{padding: 8}} key={tokenId}>
                            <img src={`https://etherbulls.org/static/nfts/${tokenId}/image.png`} style={{width: 200, height: 'auto'}}/>
                            {/* <img src={`unrevealed.png`} style={{width: 200, height: 'auto'}}/> */}
                            {metadata && <span style={{fontWeight: 'bold', fontSize: 14, marginTop: 8}}>{metadata.name}</span>}
                            {/* <span style={{fontWeight: 'bold', fontSize: 14, marginTop: 8}}>Unrevealed</span> */}
                        </Box>
                    );
                })}
            </Box>
        </Box>
    )
}