import * as Web3 from 'web3';

import Web3Modal from "web3modal";


class Web3Manager {
    constructor() {
        this.loading = false;
        this.isAvailable = false;
        this.provider = null;
        this.account = null;
        this.chainId = null;
        this.web3 = null;

        setInterval(async () => {
            if (this.web3) {
                this.isAvailable = await this.web3.eth.net.isListening();
                this.chainId = await this.web3.eth.getChainId();
            }
            else {
                this.isAvailable = false;
            }
        }, 3000);
    }

    connect = async () => {

        const providerOptions = {
            /* See Provider Options Section */
        };

        const web3Modal = new Web3Modal({
            network: "mainnet", // optional
            cacheProvider: true, // optional
            providerOptions // required
        });

        const provider = await web3Modal.connect();

        // Subscribe to accounts change
        provider.on("accountsChanged", (accounts) => {
            console.log(accounts);
            this.account = (accounts && accounts.length > 0) ? accounts[0] : null;
        });

        // Subscribe to chainId change
        provider.on("chainChanged", (chainId) => {
            console.log(chainId);
            this.chainId = chainId
        });

        // Subscribe to provider connection
        provider.on("connect", (info) => {
            console.log(info);
        });

        // Subscribe to provider disconnection
        provider.on("disconnect", (error) => {
            console.log(error);
        });

        this.provider = provider;

        const web3 = new Web3(provider);

        this.web3 = web3;
    }
}


const WM = new Web3Manager();


const useWeb3 = () => {
    // console.log(WM)
    let loading = WM.loading;
    // let isAvailable = !!WM.web3;
    let isAvailable = true;
    let provider = WM.provider;
    let account = WM.account;
    let chainId = WM.chainId;
    let connect = WM.connect;
    let switchChain = () =>{};

    // let loading = false;
    // let isAvailable= false;
    // let provider= false;
    // let account= false;
    // let chainId= false;
    // let connect= ()=>{};
    // let switchChain = () =>{};

    return {
        loading,
        isAvailable,
        provider,
        account,
        chainId,
        connect,
        switchChain
    }
}


export { useWeb3 };