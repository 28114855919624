import * as React from 'react';

import Box from '@/components/Box';
import RoadMap from '@/components/RoadMap';

import {ethers} from 'ethers';

// import { useWeb3 } from 'react-web3-provider';
import { useWeb3 } from '@/web3/js/web3utils';
import { useWeb3React } from '@web3-react/core';
import { injected } from '@/web3/js/connectors';
import DiscordLogoWhite from '@/assets/DiscordLogoWhite.png';
import DiscordLogoWhiteSVG from '@/assets/DiscordLogoWhiteSVG.svg';
import TwitterLogoWhite from '@/assets/TwitterLogoWhite.svg';
import OpenSeaLogo from '@/assets/logos/opensea.png';
import EtherscanLogo from '@/assets/logos/etherscan.jpg';
import CountDown from 'count-down-react';

import {makeContract} from '@/web3/contracts/EtherBulls';

import {useMediaQuery} from 'react-responsive';

import toast, { Toaster } from 'react-hot-toast';
import Marquee from 'react-fast-marquee';

import SplashBear from '@/assets/nfts/bull_bear_fight.png';


import Bull1 from "@/assets/nfts/best/black_9_melon_silver_bloodshot_crayon_tech.png";
import Bull2 from "@/assets/nfts/best/blue_11_melon_diamond_crosseyed_smirk_cape.png";
import Bull3 from "@/assets/nfts/best/brown_13_dirtywhite_silver_sunglasses_tongue_out_tech.png";
import Bull4 from "@/assets/nfts/best/rainbow_13_lightsalmonpink_normal_glasses_small_grimace_normal.png";
import Bull5 from "@/assets/nfts/best/tiger_7_magicmint_normal_x_crayon_normal.png";
import Bull6 from "@/assets/nfts/best/zebra_6_lightsalmonpink_diamond_wink_missing_teeth_cape.png";

import Bear1 from "@/assets/nfts/best/black_19_melon_bronze_bloodshot_open_none.png";
import Bear2 from "@/assets/nfts/best/rainbow_11_lightsalmonpink_silver_fire_smirk_tech.png";
import Bear3 from "@/assets/nfts/best/red_20_melon_normal_x_grimace_tech.png";
import Bear4 from "@/assets/nfts/best/red_7_lightsalmonpink_diamond_fire_closed_straight_barrel.png";
import Bear5 from "@/assets/nfts/best/white_2_dirtywhite_stone_sunglasses_closed_squiggle_normal.png";

import './HomeScene.css';

function millisecondsToDaysHoursMinutesSeconds(ms) {
    ms += 999; // add 999ms to round remaining milliseconds up to the next second
    const days = Math.floor(ms / (24 * 1000 * 60 * 60));
    const hours = Math.floor(ms / (1000 * 60 * 60)) % 24;
    const minutes = Math.floor(ms / (1000 * 60)) % 60;
    const seconds = Math.floor(ms / 1000) % 60;

    return [days, hours, minutes, seconds];
}

function leadingZero(num) {
    return num >= 0 && num < 10 ? '0' + num : +num;
}

function getLaunchString() {
    let launchDate = new Date(parseInt(process.env.REACT_APP_SALE_START_TIME));
    let month = launchDate.getMonth() + 1;
    let day = launchDate.getDate();
    let hour = ("0" + launchDate.getHours()).slice(-2);
    let mins = ("0" + launchDate.getMinutes()).slice(-2);

    return `${month}/${day} at ${hour}:${mins} UTC`;
}


const CoundownRenderer = ({ days, hours, minutes, seconds, completed }) => {
    return (
      <div className="cr-container">
        <h1>Countdown</h1>
        <ul style={{padding: 0}}>
          <li className="cr-li">
            <span id="days">{days}</span>days
          </li>
          <li className="cr-li">
            <span id="hours">{hours}</span>Hours
          </li>
          <li className="cr-li">
            <span id="minutes">{minutes}</span>Minutes
          </li>
          <li className="cr-li">
            <span id="seconds">{seconds}</span>Seconds
          </li>
        </ul>
      </div>
    );
  };


function useInterval(callback, delay) {
    const intervalRef = React.useRef(null);
    const savedCallback = React.useRef(callback);
    React.useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);
    React.useEffect(() => {
      const tick = () => savedCallback.current();
      if (typeof delay === 'number') {
        intervalRef.current = window.setInterval(tick, delay);
        return () => window.clearInterval(intervalRef.current);
      }
    }, [delay]);
    return intervalRef;
  }


export default function HomeScene() {
    const initialSaleTimeRemaining = parseInt(process.env.REACT_APP_SALE_START_TIME) - Date.now();
    const [timeRemainingMs, setTimeRemainingMs] = React.useState(initialSaleTimeRemaining);
    const [currentSupply, setCurrentSupply] = React.useState(null);
    const [isSaleActive, setIsSaleActive] = React.useState(null);
    const [inputNumMint, setInputNumMint] = React.useState('');
    const [error, setError] = React.useState(null);
    const [isAvailable, setAvailable] = React.useState('');
    const [provider, setProvider] = React.useState('');

    const [activeBullIdx, setBullIdx] = React.useState(0);
    const [activeBearIdx, setBearIdx] = React.useState(0);



    useInterval(()=>{
        setBearIdx(activeBearIdx => (activeBearIdx + 1) % 5)
        setBullIdx(activeBullIdx => (activeBullIdx + 1) % 6)
    }, 3000);

    const { connector, library, chainId, account, activate, deactivate, active } = useWeb3React();
    const loading=false;
    const connect = () => activate(injected).catch(()=>{});

    React.useEffect(() => {
        // const injected = new InjectedConnector({ supportedChainIds: [1, 3, 4, 5, 42] });
        // setAvailable(await injected.isAuthorized());
        // console.log(await injected.isAuthorized())

        async function setprov() {
            if (connector) {
                setProvider(new ethers.providers.Web3Provider(await connector.getProvider()));
            }
            else {
                setProvider("");
            }
            console.log("ping", connector, provider)
            // setAvailable(!!connector);
            setAvailable(true);

        }

        setprov();
    }, []);



    // const {loading, isAvailable, provider, account, connect} = useWeb3();

    const contractRef = React.useRef(null);
    const refreshSupplyTimeoutRef = React.useRef(null);

    const isMobile = useMediaQuery({
        query: '(max-width: 1000px)',
    });

    const isSuperSmall = useMediaQuery({
        query: '(max-width: 500px)',
    });

    const isSuperDuperSmall = useMediaQuery({
        query: '(max-width: 384px)',
    });

    const updateTimeRemainingMs = () => {
        const timeRemainingMs = parseInt(process.env.REACT_APP_SALE_START_TIME) - Date.now();
        setTimeRemainingMs(Math.max(0, timeRemainingMs));
        if (timeRemainingMs > 0) {
            setTimeout(updateTimeRemainingMs, 1000);
        }
    };

    const updateCurrentSupply = async () => {
        try {
            const [supply, isSaleActive] = await Promise.all([
                contractRef.current.totalSupply(),
                contractRef.current.saleIsActive(),
            ]);
            setCurrentSupply(supply);
            setIsSaleActive(isSaleActive);
        } catch (err) { }

        if (currentSupply < 6666) {
            refreshSupplyTimeoutRef.current = setTimeout(updateCurrentSupply, 5000);
        }
    };

    React.useEffect(() => {
        updateTimeRemainingMs();
    }, []);

    React.useEffect(() => {
        if (!provider) return;
        contractRef.current = makeContract(provider);
        if (account) {
            const signer = provider.getSigner();
            contractRef.current = contractRef.current?.connect(signer);
        }
        refreshSupplyTimeoutRef.current && clearTimeout(refreshSupplyTimeoutRef.current);
        updateCurrentSupply();
    }, [provider, account]);

    const [daysRemaining, hoursRemaining, minutesRemaining, secondsRemaining] = millisecondsToDaysHoursMinutesSeconds(timeRemainingMs);

    const renderSaleCountdownOrRemainingSupply = () => {
        if (timeRemainingMs <= 0 || isSaleActive) {
            if (loading) return <span style={{color: '#252627'}}>Loading sale status...</span>;
            if (!isAvailable) {
                return (
                    <a href="https://metamask.io/" target="_blank" style={{textDecoration: 'none', color: 'white'}}> Please Install<span style={{color: 'orange', cursor: 'pointer'}}> MetaMask</span></a>
                );
            }
            if (currentSupply === null || isSaleActive === null) return <span style={{color: '#252627'}}>Loading sale status...</span>;

            if (currentSupply.toString() === '6666') {
                return (
                    <span style={{color: 'rgb(153 44 44)'}}>Sold out.</span>
                );
            } else {
                if (isSaleActive) {
                    return (
                        <span><span style={{color: 'red'}}>{6666 - currentSupply.toNumber()}</span> remaining.</span>
                    );
                } else {
                    return (
                        <span style={{color: 'yellow'}}>Minting starts momentarily.</span>
                    );
                }
            }
        } else {
            return (
                <span>Sale not active yet.</span>
                // <span>
                //     Sale begins in <span className="cr-homescene-countdown-time">{daysRemaining}d {leadingZero(hoursRemaining)}h {leadingZero(minutesRemaining)}m {leadingZero(secondsRemaining)}s</span>.
                // </span>
            );
        }
    };

    const onInputNumMintChange = (ev) => {
        if (ev.target.value === '') {
            setInputNumMint('');
            return;
        }
        const num = +ev.target.value;
        if (isNaN(num)) {
            return;
        }
        if (num < 1 || num > 50) {
            return;
        }
        setInputNumMint(num);
    };

    const onMintClick = async () => {
        if (!contractRef.current) return;
        const numToMint = +inputNumMint;
        if (isNaN(numToMint) || numToMint < 1 || numToMint > 50) return;

        const total = ethers.utils.parseEther('0.06').mul(numToMint);

        try {
            const tx = await contractRef.current.mint(
                numToMint,
                {
                    value: total,
                }
            );
            toast.promise(
                tx.wait(),
                {
                    loading: (
                        <span>Transaction pending...<br /><a style={{textDecoration: 'underline', color: 'blue'}} href={`https://etherscan.io/tx/${tx.hash}`} target="_blank">View on Etherscan →</a></span>
                    ),
                    success: <span><span style={{fontWeight: 'bold'}}>Success!</span><br /><a style={{textDecoration: 'underline', color: 'blue'}} href={`https://etherscan.io/tx/${tx.hash}`} target="_blank">View on Etherscan →</a></span>,
                    error: <span><span style={{fontWeight: 'bold'}}>Transaction error</span><br /><a style={{textDecoration: 'underline', color: 'blue'}} href={`https://etherscan.io/tx/${tx.hash}`} target="_blank">View on Etherscan →</a></span>,
                },
            );
        } catch (err) {
            if (err.message.includes('insufficient funds')) {
                setError('Insufficient wallet balance');
            } else {
                setError(err.message);
            }
        }
    };

    const renderMintFormIfSaleActive = () => {

        // return (
        //     <div style="display: flex; flex-direction: column; margin-top: 16px; margin-bottom: 16px; border-radius: 16px; background-color: rgb(34, 34, 34); color: rgb(255, 255, 255); padding: 20px; box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;">
        //         <span style="font-weight: bold; text-align: center; margin-bottom: 8px; font-size: 20px;">Purchase</span>
        //         <div style="display: flex; flex-direction: row; background-color: rgba(255, 255, 255, 0.2); margin-top: 8px; border-radius: 8px; align-items: center;">
        //             <span style="margin-left: 8px; font-weight: bold; font-size: 14px;">Quantity</span>
        //             <input type="number" placeholder="1000" class="numeric" min="1" max="1000" value="" style="flex: 1 1 0%; font-size: 32px; border: none; outline: none; text-align: right; background-color: transparent; color: rgb(255, 255, 255); padding-right: 10px;"/>
        //         </div>
        //         <div style="display: flex; flex-direction: row; margin-top: 12px;">
        //             <span style="margin-left: 8px; font-weight: bold; font-size: 12px; opacity: 0.54;">Total</span>
        //             <div style="flex: 1 1 0%;"></div>
        //             <span style="margin-right: 8px; font-size: 12px;">--</span>
        //         </div>
        //         <div style="display: flex; flex-direction: row; align-items: center; justify-content: center; margin-top: 12px; margin-bottom: 12px;">
        //             <label for="agree" style="font-size: 12px; margin-left: 4px; font-weight: bold; opacity: 1; color: rgb(187, 187, 187);">By purchasing, you are agreeing to the <a href="#/tos" style="color: rgb(238, 238, 238);">Terms of Service</a> and are aware that you are unironically buying tokenized shapes on the blockchain.</label>
        //         </div>
        //         <button class="animated-rainbow-bg" style="border: none; border-radius: 16px; padding: 16px;">
        //             <span style="font-weight: bold; color: rgb(255, 255, 255); font-size: 16px;">Connect Wallet</span>
        //         </button>
        //     </div>)

        if (!isSaleActive) return null;

        const numToMint = +inputNumMint;
        const mintButtonDisabled = isNaN(numToMint) || numToMint < 1 || numToMint > 50;
        const total = !mintButtonDisabled && ethers.utils.parseEther('0.06').mul(numToMint);

        return (
            <Box style={{marginTop: 8}}>
                {error && <span style={{fontSize: 12, fontWeight: 'bold', color: 'red', marginBottom: 4}}>{error}</span>}
                <Box direction="row">
                    <input
                        className="cr-homescene-mint-input"
                        type="text"
                        placeholder="Mint Quantity"
                        value={inputNumMint}
                        onChange={onInputNumMintChange}
                        style={{flex: 1}} />
                    {account && (
                        <button className={mintButtonDisabled ? 'cr-homescene-mint-disabled' : 'cr-homescene-mint'} disabled={mintButtonDisabled} onClick={onMintClick}>
                            Mint{total && ` (${ethers.utils.formatEther(total)}Ξ)`}
                        </button>
                    )}
                    {!account && (
                        <button className="cr-homescene-connect" onClick={() => connect()}>
                            Connect Wallet
                        </button>
                    )}
                </Box>
                <Box style={{marginTop: 8}} direction="row">
                    <span style={{fontSize: 12, fontWeight: 'bold', color: '#252627'}}><span style={{whiteSpace: 'nowrap'}}>Max 50 per transaction.</span></span>
                </Box>
            </Box>
        );
    };

    const bullImages = [
        Bull1,
        Bull2,
        Bull3,
        Bull4,
        Bull5,
        Bull6,
    ];

    const bearImages = [
        Bear1,
        Bear2,
        Bear3,
        Bear4,
        Bear5,
    ];

    return (
        <Box className="cr-homescene">
            <Box className="cr-homescene-banner" direction={isMobile ? 'column' : 'row'} alignItems="center">
                <Box flex={1} style={isMobile ? {} : {width: 0, marginRight: 8}} alignItems="flex-end">
                    <Box style={{maxWidth: 540, textAlign: isMobile ? 'center' : 'left'}} alignItems={isMobile ? 'center' : 'flex-start'}>
                        <span className="cr-homescene-title">Crypto's biggest <span style={{color: '#03E298'}}> bulls </span> and gayest <span style={{color: 'rgb(153 44 44)'}}> bears. </span> <br /></span>
                        <span className="cr-homescene-subtitle">Etherbulls is offered as a limited <span style={{fontWeight: 'bold', fontSize: 18}}> NFT collection </span> of 6666 unique Bulls and Bears. Each hand-crafted digital artwork signifies a bullish or bearish attitude towards over 20 top cryptocurrencies. By owning an Ether bull or bear, you will become part of an exclusive community deciding the fate of the market sentiment towards each listed currency.</span>
                            <span style={{flex: "1 1 0%", flexDirection: "row", alignItems: "center"}}>
                                {/* <a href="https://discord.gg/NMDgZsxpdM" target="_blank" style={{display: 'flex', justifyContent: 'center', marginLeft: 24, marginTop: 4}}><img src={DiscordLogoWhite} style={{width: 16, height: 18}} /></a> */}
                                <a className="cr-homescene-splash-links" href="https://discord.gg/NMDgZsxpdM" target="_blank" style={{}}><img src={DiscordLogoWhiteSVG} style={{width: 32, height: 36}} /></a>
                                {/* <a href="https://twitter.com/etherbulls" target="_blank" style={{display: 'flex', justifyContent: 'center', marginLeft: 20, marginTop: 2}}><img src={TwitterLogoWhite} style={{width: 16, height: 18}} /></a> */}
                                <a className="cr-homescene-splash-links" href="https://twitter.com/etherbulls" target="_blank" style={{}}><img src={TwitterLogoWhite} style={{width: 32, height: 36}} /></a>
                                <a className="cr-homescene-splash-links" href="https://etherscan.io/address/0x48bd129c9b2ee9e1ed69c9627fd6ad3c85bd86a3" target="_blank"><img src={EtherscanLogo} style={{width: 32, height: 32, backgroundColor: 'white', borderRadius: 999}} /></a>
                                <a className="cr-homescene-splash-links" href="https://opensea.io/collection/etherbulls-nfts" target="_blank"><img src={OpenSeaLogo} style={{width: 32, height: 32}}/></a>
                            </span>
                        <span className="cr-homescene-countdown"><span className="cr-homescene-countdown-price" style={{whiteSpace: 'nowrap'}}>0.06Ξ</span> each. <span style={{whiteSpace: 'nowrap'}}>{renderSaleCountdownOrRemainingSupply()}</span></span>
                        {renderMintFormIfSaleActive()}
                    </Box>
                </Box>
                <Box flex={1} direction="row" style={isMobile ? {marginTop: 16, flex: 1} : {width: 0, marginLeft: 8}}>

                    {
                        isMobile ?
                        <img src={SplashBear} style={{
                            maxWidth: '340px',
                            maxHeight: '400px',
                            margin: 'auto',
                        }} /> :
                        <img src={SplashBear} style={{
                            maxWidth: '500px',
                            maxHeight: '600px',
                            margin: 'auto',
                        }} />
                    }

                </Box>
            </Box>
            <Box className="cr-homescene-showroom" style={{overflow: 'hidden'}}>

                <Box direction={isMobile ? 'column' : 'row'} alignItems="center">
                    <Box flex={1} style={isMobile ? {} : {width: 0, marginRight: 8}} alignItems="flex-end">
                        <img src={bullImages[activeBullIdx]} className="cr-homescene-marquee-img" style={{borderRadius: 20, maxWidth: 256, maxHeight: 352}} />
                    </Box>
                    <span style={{fontWeight: "bold", margin: 40}}> VS </span>
                    <Box flex={1} direction="row" style={isMobile ? {marginTop: 16, flex: 1} : {width: 0, marginLeft: 8}}>
                        <img src={bearImages[activeBearIdx]} className="cr-homescene-marquee-img" style={{borderRadius: 20, maxWidth: 256, maxHeight: 352}} />
                    </Box>
                </Box>

            </Box>
            <Box className="cr-homescene-socials" alignItems="center">
                {/* <span className="cr-homescene-info-title">{getLaunchString()}</span> */}
                <CountDown date={new Date(Math.max(Date.now(), parseInt(process.env.REACT_APP_SALE_START_TIME)))} renderer={CoundownRenderer} />
                <span style={{textAlign: 'center', lineHeight: 1.5}}>Never miss a thing.<br /> For more information on our faq, etc, join our <a href="https://discord.gg/NMDgZsxpdM" target="_blank" className="cr-homescene-discord-link">Discord</a> and follow us on <a href="https://twitter.com/etherbulls" target="_blank" className="cr-homescene-twitter-link">Twitter</a> </span>
                <Box direction="row" style={{marginTop: 80}}>
                    <RoadMap />
                </Box>
            </Box>
            <Toaster position="top-right" />
        </Box>
    );
}